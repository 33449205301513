.swiper-pagination-bullet {
  display: inline-flex;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  opacity: 1;
  background-color: transparent;
  margin: 0 !important;
  transition: background-color $standard $ease-in-out, box-shadow $standard $ease-in-out;
  &::before {
    @include pseudo();
    position: relative;
    margin: auto;
    background-color: rgba($charcoal-grey, 0.5);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    transition: background-color $short $ease-in-out;
  }
  &-active {
    background-color: rgba($charcoal-grey, 0.2);
    &::before {
      background-color: $black;
    }
  }
  @media (hover: hover) {
    &:hover {
      &::before {
        background-color: $black;
      }
    }
  }
}

.swiper-button-next {
  background-image: url('../assets/img/icons/arrow.svg');
  width: 44px;
  height: 44px;
  border-radius: 50%;
  right: 30px;
  transition: all $short $ease-in-out;
  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  @include xs {
    display: none;
  }
}

.swiper-button-prev {
  background-image: url('../assets/img/icons/arrow.svg');
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition: all $short $ease-in-out;
  left: 30px;
  transform: rotate(180deg);
  @media (hover: hover) {
    &:hover {
      transform: rotate(180deg) scale(1.1);
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  @include xs {
    display: none;
  }
}

.PaymentRequestButton {
  background-color: white;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.SubscriptionPage {
  .fb_dialog {
    right: 480px !important;
    @media (max-width: 1329px) {
      right: 410px !important;
    }
    @media (max-width: 1019px) {
      right: 360px !important;
    }
    @include sm {
      right: 18pt !important;
    }
  }

  .fb_customer_chat_bounce_in_v2,
  .fb_customer_chat_bounce_out_v2 {
    right: 470px !important;
    @media (max-width: 1329px) {
      right: 400px !important;
    }
    @media (max-width: 1019px) {
      right: 350px !important;
    }
    @include sm {
      right: 9pt !important;
    }
  }
}
