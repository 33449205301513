.Loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: rgba(black, 0.5);
  }

  &_block {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
