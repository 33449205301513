body {
  font-family: $font-family;
  font-size: 16px;
  line-height: 1.33;
  color: $charcoal-grey;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.17;
  margin: 0;
}

p,
figure {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main {
  font-family: $font-family;
  display: block;
}
