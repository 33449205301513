#applePayIcon {
  position: relative;
  top: -2px;
}

.VisuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  bottom: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Focus {
  border-radius: 20px;
  transition: box-shadow $standard $ease-in-out;
  &:focus {
    box-shadow: 0 0 5px 3px $light-teal;
    outline: none;
  }
  &_second {
    &:focus {
      box-shadow: 0 0 5px 3px $light-peach;
    }
  }
}

.Container {
  margin: auto;
  padding: 0 40px;
  max-width: $container-width;
  @media (max-width: 767px) {
    padding: 0 30px;
  }
}

.Section {
  margin-bottom: 100px;
  @include sm {
    margin-bottom: 60px;
  }
  @include xs {
    margin-bottom: 40px;
  }
}

.Icon > path {
  fill: currentColor;
}

.ButtonIcon {
  flex-shrink: 0;
  margin-right: 10px;
  @include sm {
    height: 18px;
    margin-right: 8px;
  }
}

.Title {
  color: $light-blue-grey;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 16px;
  @include sm {
    font-size: 13px;
    letter-spacing: 0.8px;
    margin-bottom: 12px;
  }
}

.TitleIcon {
  display: block;
  margin: auto;
  margin-bottom: 10px;
  @include sm {
    width: 26px;
    height: 26px;
    margin-bottom: 8px;
  }
}

.Bullets {
  margin-bottom: 12px;
  text-align: center;
  &_right {
    display: inline-block;
    text-align: right;
    padding-right: 25%;
    @media (max-width: 767px) {
      padding-right: 18%;
    }
    @include xs {
      text-align: center;
      padding-right: 0;
      margin-bottom: 30px;
    }
  }
}

.LogoLink {
  display: inline-flex;
  padding: 3px 8px;
  margin-left: -8px;
  text-decoration: none;
}

.Link {
  display: inline-flex;
  padding: 2px 4px;
  color: $lightblue;
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  text-decoration: none;
  align-items: center;
  & > svg {
    margin-right: 4px;
  }
  &_teal {
    color: $light-teal;
  }
  &_button {
    font-family: inherit;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

.Submit {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;
}

.ListItem {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.Form {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-color: rgba($almost-black, 0.5);
  padding: 40px;
  @include md {
    padding: 24px;
  }
}

.FormLink {
  text-align: right;
  margin: 0 -4px;
  margin-bottom: 16px;
  &:not(:first-child) {
    margin-top: 4px;
  }
  &_left {
    text-align: left;
  }
}

.FormTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.5px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 16px;
  & > svg {
    top: -1px;
    margin-right: 8px;
  }
  @include md {
    font-size: 13px;
  }
}

.FormTip {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(white, 0.5);
  margin-top: 32px;
}

.SubscriptionAside {
  position: relative;
  padding: 60px 40px;
  overflow: hidden;
  @media (max-width: 1329px) {
    padding-top: 50px;
  }
  @media (max-width: 1019px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include sm {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include xs {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.Fetching {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  &_small {
    min-height: auto;
  }
}
