@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.FadeIn {
  animation: fadeIn $longer $ease-in-out both;
}

@keyframes fadeInTopSmall {
  from {
    transform: translate3d(0, -10%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.FadeInTopSmall {
  animation: fadeInTopSmall $longer $ease-in-out both;
}

@keyframes fadeInTop {
  from {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.FadeInTop {
  animation: fadeInTop $longer $ease-in-out both;
}

@keyframes fadeInLeft {
  from {
    transform: translate3d(-25%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translate3d(25%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInBottomSmall {
  from {
    transform: translate3d(0, 10%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  from {
    transform: translate3d(0, 25%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInScreenLeft {
  from {
    transform: translate3d(-15%, -50%, 0) rotate(-20deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) rotate(0);
    opacity: 1;
  }
}

@keyframes fadeInScreenRight {
  from {
    transform: translate3d(15%, -50%, 0) rotate(20deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) rotate(0);
    opacity: 1;
  }
}

@keyframes scaleLeft {
  from {
    opacity: 1;
    transform: translate3d(-30%, 0, 0) scale(0.6);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes scale {
  from {
    opacity: 1;
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: scale(1.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shine {
  to {
    opacity: 1;
    transform: translate3d(-130%, 0, 0);
  }
}

@keyframes translateDownUp {
  from {
    box-shadow: 0 0 0 0 rgba($charcoal-grey, 0.8);
    transform: translate3d(0, 0, 0);
  }
  35% {
    box-shadow: 0 0 0 20px rgba($charcoal-grey, 0);
    transform: translate3d(0, 0, 0);
  }
  45% {
    box-shadow: 0 0 0 20px rgba($charcoal-grey, 0);
    transform: translate3d(0, 40%, 0);
  }
  65% {
    box-shadow: 0 0 0 20px rgba($charcoal-grey, 0);
    transform: translate3d(0, 0, 0);
  }
  75% {
    box-shadow: 0 0 0 20px rgba($charcoal-grey, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    box-shadow: 0 0 0 20px rgba($charcoal-grey, 0);
    transform: translate3d(0, 0, 0);
  }
}

.TranslateDownUp {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation: translateDownUp 2500ms $ease-in-out both infinite;
}

.LoaderEnter {
  opacity: 0;
}

.LoaderEnterActive {
  opacity: 1;
  transition: opacity $shortest $ease-in-out;
}

.LoaderExit {
  opacity: 1;
}

.LoaderExitActive {
  opacity: 0;
  transition: opacity $shortest $ease-in-out;
}
