$background: #fafafa;
$charcoal-grey: #3b4554;
$light-teal: #96e3dd;
$tiffany-blue: #7de5dd;
$light-peach: #ffbdb5;
$light-sage: #c5e4b9;
$peachy-pink: #ff8c80;
$wheat: #f3e38f;
$sandy: #f2df7e;
$light-peach-two: #ffd0cb;
$pale-salmon: #ffa59c;
$egg-shell: #fae3c7;
$pinkish-tan: #e79085;
$french-blue: #4267b2;
$soft-blue: #61b7e8;
$lightblue: #7fbeeb;
$off-white: #f2f1ee;
$light-grey: #d9d8cd;
$light-blue-grey: #d4d6d9;
$light-grey-blue: #a7b5ba;
$very-light-pink: #c5c5c5;
$greyish: #a3a3a2;
$greyish-brown: #4a4a4a;
$dark: #232c38;
$dark-two: #2f3846;
$dark-three: #2a2e3a;
$black: #131313;
$almost-black: #070b18;

$font-family: 'Boston', sans-serif;
$container-width: 1424px;

$shortest: 150ms;
$shorter: 200ms;
$short: 250ms;
$standard: 300ms;
$medium: 500ms;
$long: 800ms;
$longer: 1000ms;
$longest: 1200ms;

$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
